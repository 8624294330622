<template>
  <b-card>
    <div>
      <validation-observer ref="addThreat">
        <span>
          <h4>Add Threats</h4>
        </span>
        <div>
          <label>Name</label>
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
            class="mb-1"
          >
            <input
              v-model="assetName"
              placeholder="name"
              class="mr-bottom form-control input-sm flex"
            />
            <span class="validation" v-if="duplicateName">
              Please enter valid name</span
            >
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <div>
          <label>Threat Value</label>
          <validation-provider
            #default="{ errors }"
            name="Threat Value"
            rules="required"
            class="mb-1"
          >
            <v-select
              v-model="selectedData"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <label>Description</label>
        <b-form-textarea
          rows="1"
          v-model="threatDescription"
          placeholder="description"
          class="mr-bottom"
        />
        <div class="align-right">
          <button
            @click="addThreat()"
            class="btn pointer btn-primary btn-sm margin-right"
          >
            Add
          </button>
          <button @click="cancel()" class="btn pointer btn-sm">Cancel</button>
        </div>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import Vue from "vue";
import { BFormTextarea, BSidebar, VBToggle, BCard } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
export default {
  data: () => ({
    allThreat: [],
    threatDescription: "",
    assetName: "",
    addThreatData: false,
    editThreatData: false,
    editThreatID: "",
    hideSideBar: false,
    duplicateName: false,
    currentPage: 1,
    lastPage: null,
    perPage: 10,
    rows: 1,
    searchResult: "",
    selectedData: [],
    dir: "ltr",
    option: [
      { title: "Very Low", value: 1 },
      { title: "Low", value: 2 },
      { title: "Medium", value: 3 },
      { title: "High", value: 4 },
      { title: "Very High", value: 5 },
    ],
    required,
  }),
  components: {
    BFormTextarea,
    BSidebar,
    BCard,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  props: {},
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    addThreat() {
          this.$refs.addThreat.validate().then(valid => {
        if (!valid) {
          // do stuff if not valid.
        }
        else{
      const body = {
        name: this.assetName,
        description: this.threatDescription,
        threat_value: this.selectedData.value,
      };
      const duplicateData = this.allThreat.filter(
        (ele) => ele.name.toLowerCase() == this.assetName.toLowerCase()
      );
      if (!duplicateData.length) {
        const options = {
          method: "POST",
          data: body,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_ONECSF_API_URL + `/threats`,
        };
        this.$http(options)
          .then((res) => {
            this.hideSideBar = !this.hideSideBar;
            this.duplicateName = false;
            this.assetName = "";
            this.threatDescription = "";
            this.$router.push({ name: "threat" });
          })
          .catch((err) => {
            console.log(err);
          });
          
      } else {
        this.duplicateName = true;
      }
    }}) ;
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.width-70 {
  width: 70%;
}
.header {
  font-size: 20px;
  font-weight: 600;
}
.align-center-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.align-center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.padding-20 {
  padding: 20px;
}
.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #7367f0 !important;
  color: #ffff;
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
.icon-box-new {
  width: 25px;
  height: 25px;
  background-color: #7367f0;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}
.text-primary-black {
  color: #ffff;
}
.mr-bottom {
  margin-bottom: 20px;
}
.pointer {
  cursor: pointer;
}
.validation {
  color: red;
  font-size: 12px;
  position: relative;
  top: -10px;
}
.table th,
.table td {
  border-top: 1px solid #e3e0e9;
}
.rowgroup tr:hover {
  background-color: #bfcad0;
  cursor: pointer;
}
.text-align-right {
  text-align: right;
}
</style>
